@import "../variables.scss";

.headerPage{
    .container{
        height: 350px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 4em;
        text-align: center;

        h2{
            font-family: $dancingMedium;
        }

    }

}

@media (max-width: $laptop){

    .headerPage{
        .container{;
            font-size: 3em;

            h2{
                line-height: 0.95em ;
            }

        }

    }
}
