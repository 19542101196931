@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.allPlaces {
  margin: 25px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}
.allPlaces .element {
  flex: 0 0 25%;
  text-align: center;
  background: #1C1C1B;
  color: #EEEADE;
  font-size: 24px;
  font-family: "CaviarDreams_Bold", sans-serif;
  margin: 0px 25px;
  padding: 25px 0;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}

footer {
  background-color: #1C1C1B;
  color: #EEEADE;
}
footer .container,
footer .footerContainer {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 16px;
  padding-bottom: 20px;
  /**
      Grosse partie du footer
  **/
  /**
      Message de prévention
  **/
}
footer .container .contactContainer,
footer .container .horairesContainer,
footer .container .followUsContainer,
footer .footerContainer .contactContainer,
footer .footerContainer .horairesContainer,
footer .footerContainer .followUsContainer {
  margin: 0 15px;
  word-break: break-word;
  /* new */
}
footer .container .contactContainer h3,
footer .container .horairesContainer h3,
footer .container .followUsContainer h3,
footer .footerContainer .contactContainer h3,
footer .footerContainer .horairesContainer h3,
footer .footerContainer .followUsContainer h3 {
  font-size: 30px;
  font-family: "CaviarDreams", sans-serif;
  font-weight: 400;
  margin-top: 20px;
}
footer .container .contactContainer hr,
footer .container .horairesContainer hr,
footer .container .followUsContainer hr,
footer .footerContainer .contactContainer hr,
footer .footerContainer .horairesContainer hr,
footer .footerContainer .followUsContainer hr {
  width: 50px;
  margin: 10px 0 20px 0;
  word-break: break-word;
  /* new */
}
footer .container .contactContainer .containerElement li,
footer .container .horairesContainer .containerElement li,
footer .container .followUsContainer .containerElement li,
footer .footerContainer .contactContainer .containerElement li,
footer .footerContainer .horairesContainer .containerElement li,
footer .footerContainer .followUsContainer .containerElement li {
  padding: 3.5px 0;
  word-break: break-word;
  /* new */
}
footer .container .contactContainer .containerElement .contactUs,
footer .container .horairesContainer .containerElement .contactUs,
footer .container .followUsContainer .containerElement .contactUs,
footer .footerContainer .contactContainer .containerElement .contactUs,
footer .footerContainer .horairesContainer .containerElement .contactUs,
footer .footerContainer .followUsContainer .containerElement .contactUs {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
}
footer .container .contactContainer,
footer .footerContainer .contactContainer {
  flex: 2;
}
footer .container .horairesContainer,
footer .footerContainer .horairesContainer {
  flex: 2;
}
footer .container .followUsContainer,
footer .footerContainer .followUsContainer {
  flex: 1.5;
}
footer .container .followUsContainer .facebookLink,
footer .footerContainer .followUsContainer .facebookLink {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 30px;
}
footer .container .followUsContainer .facebookLink i,
footer .footerContainer .followUsContainer .facebookLink i {
  font-size: 25px;
  margin-right: 10px;
}
footer .container .followUsContainer img,
footer .footerContainer .followUsContainer img {
  max-width: 75%;
}
footer .container .preventionMessage,
footer .container .copyrightFooter,
footer .footerContainer .preventionMessage,
footer .footerContainer .copyrightFooter {
  flex: 0.5;
  font-family: "CaviarDreams_Italic", sans-serif !important;
  font-size: 12px;
}
footer .container .preventionMessage.copyrightFooter,
footer .container .copyrightFooter.copyrightFooter,
footer .footerContainer .preventionMessage.copyrightFooter,
footer .footerContainer .copyrightFooter.copyrightFooter {
  text-align: right;
}

@media (max-width: 700px) {
  .allPlaces {
    margin: 25px;
    flex-direction: column;
  }
  .allPlaces .element {
    margin: 5px 5px;
  }
}
@media (max-width: 1024px) {
  footer .container,
footer .footerContainer {
    flex-direction: column !important;
  }
  footer .container .contactContainer,
footer .container .horairesContainer,
footer .container .followUsContainer,
footer .footerContainer .contactContainer,
footer .footerContainer .horairesContainer,
footer .footerContainer .followUsContainer {
    margin: 0 15px;
    text-align: center;
  }
  footer .container .contactContainer h3:nth-child(1),
footer .container .horairesContainer h3:nth-child(1),
footer .container .followUsContainer h3:nth-child(1),
footer .footerContainer .contactContainer h3:nth-child(1),
footer .footerContainer .horairesContainer h3:nth-child(1),
footer .footerContainer .followUsContainer h3:nth-child(1) {
    margin-top: 25px;
  }
  footer .container .contactContainer hr,
footer .container .horairesContainer hr,
footer .container .followUsContainer hr,
footer .footerContainer .contactContainer hr,
footer .footerContainer .horairesContainer hr,
footer .footerContainer .followUsContainer hr {
    margin: 10px auto 10px auto !important;
  }
  footer .container .followUsContainer .facebookLink,
footer .footerContainer .followUsContainer .facebookLink {
    margin-bottom: 10px;
    justify-content: center;
  }
  footer .container .followUsContainer img,
footer .footerContainer .followUsContainer img {
    width: 250px;
    max-width: initial;
    margin-bottom: 20px;
  }
}