@import "../variables.scss";


.partenaires{
    .container{
        text-align: center;

        h2{
            font-size: 30px;
            font-family: $bold;
            margin: 50px 0 0 0;
        }

        .caroussel {

            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            flex-wrap: wrap;
            margin: 50px auto;

            img{
                height: 150px;
                width: 200px;
                object-fit: contain;
                flex: 3;
            }
        }
    }
}