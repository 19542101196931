@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.headerPage .container {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EEEADE;
  font-size: 4em;
  text-align: center;
}
.headerPage .container h2 {
  font-family: "Dancing Script SemiBold", "CaviarDreams", sans-serif;
}

@media (max-width: 1024px) {
  .headerPage .container {
    font-size: 3em;
  }
  .headerPage .container h2 {
    line-height: 0.95em;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.produits {
  margin-bottom: 75px;
}
.produits .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 50px 100px 0 50px;
}
.produits .container .left {
  flex: 1;
  text-align: right;
  margin: auto;
  display: flex;
  justify-content: right;
}
.produits .container .left .image {
  width: 500px;
  height: 700px;
}
.produits .container .center {
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.produits .container .center label {
  margin: 15px 0;
}
.produits .container .center input[type=radio]:after:hover {
  background: #8C358B;
}
.produits .container .center input[type=radio]:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -5px;
  left: -5px;
  position: relative;
  background-color: #8E8E8D;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #EEEADE;
  cursor: pointer;
}
.produits .container .center input[type=radio]:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -5px;
  left: -5px;
  position: relative;
  background-color: #8C358B;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #EEEADE;
}
.produits .container .right {
  flex: 1;
  width: 50%;
}
.produits .container .right div h2 {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.produits .container .right div hr {
  width: 150px;
  height: 2px;
  background: #1C1C1B;
  margin: 7.5px 0 12.5px 0;
}
.produits .container .right div p {
  font-size: 1.25em;
  line-height: 1.25em;
}
.produits .container .right div .promotionProduct {
  color: #EEEADE;
  display: inline-block;
  border-radius: 8px;
  font-size: 1.25em;
  font-weight: bold;
  margin: 15px 0;
  padding: 10px 20px;
  background-color: #8C358B !important;
}
.produits .offreChangement {
  text-align: right;
  width: 90%;
  margin: auto;
}

@media (max-width: 1300px) {
  .produits .container {
    flex-direction: column;
    padding: 5% 5%;
  }
  .produits .container .left .image {
    width: 400px;
    height: 600px;
  }
  .produits .container .center {
    flex-direction: row;
  }
  .produits .container .center label {
    margin: 30px 15px;
  }
  .produits .container .right {
    flex: 1;
    width: 95%;
  }
  .produits .offreChangement {
    width: 85%;
  }
  .produits .offreChangement .sautLigne {
    display: none;
  }
}
@media (max-width: 700px) {
  .produits .container .left .image {
    width: 275px;
    height: 350px;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.descriptionSection .container {
  width: 90%;
  margin: auto;
}
.descriptionSection .container .titleDescription {
  font-size: 44px;
  text-align: center;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.descriptionSection .container hr {
  width: 75px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 25px auto;
}
.descriptionSection .container .description {
  text-align: center;
  font-size: 24px;
}

@media (max-width: 700px) {
  .descriptionSection .container {
    width: 95%;
    margin: auto;
  }
  .descriptionSection .container .description {
    text-align: center;
    font-size: 24px;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.partenaires .container {
  text-align: center;
}
.partenaires .container h2 {
  font-size: 30px;
  font-family: "CaviarDreams_Bold", sans-serif;
  margin: 50px 0 0 0;
}
.partenaires .container .caroussel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
  margin: 50px auto;
}
.partenaires .container .caroussel img {
  height: 150px;
  width: 200px;
  object-fit: contain;
  flex: 3;
}

/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.section_sliderImage {
  margin-bottom: 100px;
  margin-top: 10px;
}
.section_sliderImage .container .swiper {
  width: 100%;
  height: 100%;
}
.section_sliderImage .container .swiper-slide {
  text-align: center;
  background: #EEEADE;
  height: initial;
  padding: 25px 0;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.section_sliderImage .container .swiper-slide .content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.section_sliderImage .container .swiper-slide .content .left {
  flex: 1;
  color: #1C1C1B;
}
.section_sliderImage .container .swiper-slide .content .left .image {
  width: 90%;
  height: 250px;
  border-radius: 8px;
  text-align: center;
  margin: 10px auto 0 auto;
}
.section_sliderImage .container .swiper-slide .content .left .element {
  width: 75%;
  margin: auto;
}
.section_sliderImage .container .swiper-slide .content .left .element h2 {
  font-size: 44px;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.section_sliderImage .container .swiper-slide .content .left .element hr {
  height: 2.5px;
  width: 100px;
  background: #1C1C1B;
  margin: 25px auto;
}
.section_sliderImage .container .swiper-slide .content .left .element p {
  font-size: 24px;
  line-height: 1.4em;
  margin-bottom: 25px;
}
.section_sliderImage .container .swiper-slide .content .right {
  flex: 0.5;
}
.section_sliderImage .container .swiper-slide .content .right .image {
  width: 310px;
  height: 90%;
  position: absolute;
  top: 5%;
  border-radius: 8px;
}
.section_sliderImage .container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section_sliderImage .container .swiper-button-next:after,
.section_sliderImage .container .swiper-button-prev:after {
  color: #1C1C1B;
}

@media (max-width: 1300px) {
  .section_sliderImage {
    margin-top: 10px;
  }
  .section_sliderImage .container .swiper-slide {
    padding: 0;
  }
  .section_sliderImage .container .swiper-slide .content {
    flex-direction: row;
    width: 85%;
    padding: 20px 0;
  }
  .section_sliderImage .container .swiper-slide .content .left .element {
    width: 90%;
    margin: auto;
  }
  .section_sliderImage .container .swiper-slide .content .right {
    display: none;
  }
}
@media (max-width: 700px) {
  .section_sliderImage .container .swiper-slide .content {
    width: 85%;
  }
  .section_sliderImage .container .swiper-slide .content h2 {
    font-size: 24px !important;
  }
  .section_sliderImage .container .swiper-slide .content p {
    font-size: 18px !important;
  }
}
.headerPage {
  margin-bottom: 25px;
}
.headerPage #restauration {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/grossiste/restaurant.jpg");
}

.restaurantDescription {
  margin-bottom: 50px;
}

.vinsRestaurant .container .leftBoxSection .imageBoxSection {
  background-image: linear-gradient(rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url("../images/grossiste/alliance.jpg");
}
.vinsRestaurant .container .rightBoxSection .topBoxSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/grossiste/vinsLots.jpg");
}
.vinsRestaurant .container .rightBoxSection .bottomBoxSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/grossiste/tireuse.jpg");
}

#restaurantsPartenaires .container .left .image.one {
  background-image: url("../images/grossiste/carte.jpg");
}
#restaurantsPartenaires .container .left .image.two {
  background-image: url("../images/grossiste/carte.jpg");
}
#restaurantsPartenaires .container .left .image.three {
  background-image: url("../images/grossiste/carte.jpg");
}
#restaurantsPartenaires .container .left .image.five {
  background-image: url("../images/grossiste/carte.jpg");
}
#restaurantsPartenaires .container .right a {
  background: #8C358B;
  text-decoration: none;
  color: #EEEADE;
  padding: 10px 20px;
  display: inline-block;
  margin-top: 25px;
  border-radius: 8px;
  font-family: "CaviarDreams_Bold", sans-serif;
}
#restaurantsPartenaires .container .right a:hover {
  background: #461a45;
}

.restaurantTestimonial .container .swiper-slide .content .left .images {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.restaurantTestimonial .container .swiper-slide .content .left .images .image {
  margin: 10px;
  height: 500px !important;
}
.restaurantTestimonial .container .swiper-slide .content .left .images .villadesarts1 {
  background-image: url("../images/grossiste/villadesarts/1.jpeg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .villadesarts2 {
  background-image: url("../images/grossiste/villadesarts/2.jpg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .villadesarts3 {
  background-image: url("../images/grossiste/villadesarts/3.jpg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .dauphin1 {
  background-image: url("../images/grossiste/dauphin/Dauphin 1.png");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .dauphin2 {
  background-image: url("../images/grossiste/dauphin/Dauphin 2.png");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .dauphin3 {
  background-image: url("../images/grossiste/dauphin/Dauphin 3.png");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .institution1 {
  background-image: url("../images/grossiste/institution/1.jpg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .institution2 {
  background-image: url("../images/grossiste/institution/2.jpg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .institution3 {
  background-image: url("../images/grossiste/institution/3.jpg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .etiquettes1 {
  background-image: url("../images/grossiste/etiquettes/1.jpg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .etiquettes2 {
  background-image: url("../images/grossiste/etiquettes/2.jpg");
}
.restaurantTestimonial .container .swiper-slide .content .left .images .etiquettes3 {
  background-image: url("../images/grossiste/etiquettes/3.jpg");
}

@media (max-width: 1300px) {
  .restaurantTestimonial .container .swiper-slide {
    padding: 0;
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-position: center center;
    object-fit: contain;
  }
  .restaurantTestimonial .container .swiper-slide .content .left .images {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .restaurantTestimonial .container .swiper-slide .content .left .images .image {
    margin: 10px;
    height: 500px !important;
  }
}