@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.headerPage {
  margin-bottom: 25px;
}
.headerPage #epicerie {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/grossiste/epicerie.jpg");
}

.epicerieDescription {
  margin-bottom: 50px;
}

.vinsEpicerie .container .leftBoxSection .imageBoxSection {
  background-image: linear-gradient(rgba(28, 28, 28, 0.5), rgba(28, 28, 28, 0.5)), url("../images/grossiste/alliance.jpg");
}
.vinsEpicerie .container .rightBoxSection .topBoxSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/grossiste/vinsLots.jpg");
}
.vinsEpicerie .container .rightBoxSection .bottomBoxSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/grossiste/tireuse.jpg");
}

#epiceriePartenaires .container .right a {
  background: #8C358B;
  text-decoration: none;
  color: #EEEADE;
  padding: 10px 20px;
  display: inline-block;
  margin-top: 25px;
  border-radius: 8px;
  font-family: "CaviarDreams_Bold", sans-serif;
}
#epiceriePartenaires .container .right a:hover {
  background: #461a45;
}

.epicerieTestimonial .container .swiper-slide .content .left .vival1 {
  background-image: url("../images/grossiste/vival/3.jpg");
}
.epicerieTestimonial .container .swiper-slide .content .left .gatien1 {
  background-image: url("../images/grossiste/gatien/1.jpg");
}
.epicerieTestimonial .container .swiper-slide .content .left .blangy1 {
  background-image: url("../images/grossiste/blangy/blangy.png");
}
.epicerieTestimonial .container .swiper-slide .content .left .cambremer1 {
  background-image: url("../images/grossiste/cambremer/1.png");
}
.epicerieTestimonial .container .swiper-slide .content .right .vival2 {
  background-image: url("../images/grossiste/vival/2.jpg");
}
.epicerieTestimonial .container .swiper-slide .content .right .gatien2 {
  background-image: url("../images/grossiste/gatien/2.jpg");
}
.epicerieTestimonial .container .swiper-slide .content .right .blangy2 {
  background-image: url("../images/grossiste/blangy/blangy 2.png");
}
.epicerieTestimonial .container .swiper-slide .content .right .cambremer2 {
  background-image: url("../images/grossiste/cambremer/2.png");
}

@media (max-width: 1300px) {
  .epicerieTestimonial .container .swiper-slide {
    padding: 0;
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-position: center center;
    object-fit: contain;
  }
}