@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.twoColumns {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 75%;
  margin: 50px auto;
}
.twoColumns .imageRight {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  text-align: left;
  margin-top: 50px;
}
.twoColumns .imageRight .left {
  flex: 1;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
}
.twoColumns .imageRight .left .titleSection {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
  text-align: left;
}
.twoColumns .imageRight .left .barSection {
  width: 50px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 10px 0 10px 0;
}
.twoColumns .imageRight .left .textSection {
  font-size: 26px;
}
.twoColumns .imageRight .right {
  flex: 0.75;
  text-align: center;
}
.twoColumns .imageRight .right img {
  object-fit: cover;
  width: 80%;
}
.twoColumns .imageLeft {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  text-align: left;
  margin-top: 50px;
}
.twoColumns .imageLeft .right {
  flex: 1;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
}
.twoColumns .imageLeft .right .titleSection {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
  text-align: right;
}
.twoColumns .imageLeft .right .barSection {
  width: 50px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 10px 0 10px auto;
}
.twoColumns .imageLeft .right .textSection {
  font-size: 26px;
  text-align: right;
}
.twoColumns .imageLeft .left {
  flex: 0.75;
  text-align: center;
}
.twoColumns .imageLeft .left img {
  object-fit: cover;
  width: 80%;
}

@media (max-width: 1024px) {
  .twoColumns .imageRight .right img {
    object-fit: cover;
    width: 100% !important;
  }
  .twoColumns .imageLeft .left img {
    width: 100% !important;
  }
}
@media (max-width: 700px) {
  .twoColumns {
    width: 95%;
  }
  .twoColumns .imageRight {
    margin-top: 25px;
    flex-direction: column-reverse;
    text-align: center;
  }
  .twoColumns .imageRight .left {
    display: flex;
  }
  .twoColumns .imageRight .left .titleSection {
    text-align: center;
  }
  .twoColumns .imageRight .left .barSection {
    margin: 10px auto 10px auto;
  }
  .twoColumns .imageRight .right {
    flex: 1;
    text-align: center;
  }
  .twoColumns .imageRight .right img {
    width: 75% !important;
  }
  .twoColumns .imageLeft {
    margin-top: 25px;
    flex-direction: column;
    text-align: center;
  }
  .twoColumns .imageLeft .right .titleSection {
    text-align: center;
  }
  .twoColumns .imageLeft .right .barSection {
    margin: 10px auto 10px auto;
  }
  .twoColumns .imageLeft .right .textSection {
    text-align: center;
  }
  .twoColumns .imageLeft .left {
    flex: 1;
  }
  .twoColumns .imageLeft .left img {
    width: 75% !important;
  }
}