@import "../variables.scss";

.section_sliderImage {

    margin-bottom: 100px;
    margin-top: 10px;

    .container {
        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-slide {
            text-align: center;
            background: $white;
            height: initial;
            padding: 25px 0;


            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            .content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 80%;

                .left{
                    flex: 1;
                    color: $black;

                    .image{
                        width: 90%;
                        height: 250px;
                        border-radius: 8px;
                        text-align: center;
                        margin: 10px auto 0 auto;

                    }

                    .element{
                        width: 75%;
                        margin: auto;

                        h2{
                            font-size: 44px;
                            font-family: $bold;
                        }

                        hr{
                            height: 2.5px;
                            width: 100px;
                            background: $black;
                            margin: 25px auto;
                        }

                        p{
                            font-size: 24px;
                            line-height: 1.40em ;
                            margin-bottom: 25px;
                        }


                    }
                }

                .right{
                    flex: 0.5;

                    .image{
                        //Rajouter une image dans le fichier concerné
                        width: 310px;
                        height:calc(90%);
                        position: absolute;
                        top: 5%;
                        border-radius: 8px;
                    }
                }
            }
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .swiper-button-next:after,
        .swiper-button-prev:after
        {
            color: $black;
        }

    }
}

@media(max-width: 1300px){
    .section_sliderImage {

        margin-top: 10px;

        .container {
            .swiper-slide {
                padding: 0;
                // Ajouter l'image en fonction du fichier

                .content {
                    flex-direction: row;
                    width: 85%;
                    padding: 20px 0;

                    .left{
                        .element{
                            width: 90%;
                            margin: auto;

                        }
                    }

                    .right{
                        display: none;
                    }
                }
            }
        }
    }
}

@media(max-width: $smartphone){
    .section_sliderImage {
        .container {
            .swiper-slide {
                .content {
                    width: 85%;

                    h2{
                        font-size: 24px !important;
                    }

                    p {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
}