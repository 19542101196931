@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.headerPage .container {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EEEADE;
  font-size: 4em;
  text-align: center;
}
.headerPage .container h2 {
  font-family: "Dancing Script SemiBold", "CaviarDreams", sans-serif;
}

@media (max-width: 1024px) {
  .headerPage .container {
    font-size: 3em;
  }
  .headerPage .container h2 {
    line-height: 0.95em;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.produits {
  margin-bottom: 75px;
}
.produits .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 50px 100px 0 50px;
}
.produits .container .left {
  flex: 1;
  text-align: right;
  margin: auto;
  display: flex;
  justify-content: right;
}
.produits .container .left .image {
  width: 500px;
  height: 700px;
}
.produits .container .center {
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.produits .container .center label {
  margin: 15px 0;
}
.produits .container .center input[type=radio]:after:hover {
  background: #8C358B;
}
.produits .container .center input[type=radio]:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -5px;
  left: -5px;
  position: relative;
  background-color: #8E8E8D;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #EEEADE;
  cursor: pointer;
}
.produits .container .center input[type=radio]:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -5px;
  left: -5px;
  position: relative;
  background-color: #8C358B;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #EEEADE;
}
.produits .container .right {
  flex: 1;
  width: 50%;
}
.produits .container .right div h2 {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.produits .container .right div hr {
  width: 150px;
  height: 2px;
  background: #1C1C1B;
  margin: 7.5px 0 12.5px 0;
}
.produits .container .right div p {
  font-size: 1.25em;
  line-height: 1.25em;
}
.produits .container .right div .promotionProduct {
  color: #EEEADE;
  display: inline-block;
  border-radius: 8px;
  font-size: 1.25em;
  font-weight: bold;
  margin: 15px 0;
  padding: 10px 20px;
  background-color: #8C358B !important;
}
.produits .offreChangement {
  text-align: right;
  width: 90%;
  margin: auto;
}

@media (max-width: 1300px) {
  .produits .container {
    flex-direction: column;
    padding: 5% 5%;
  }
  .produits .container .left .image {
    width: 400px;
    height: 600px;
  }
  .produits .container .center {
    flex-direction: row;
  }
  .produits .container .center label {
    margin: 30px 15px;
  }
  .produits .container .right {
    flex: 1;
    width: 95%;
  }
  .produits .offreChangement {
    width: 85%;
  }
  .produits .offreChangement .sautLigne {
    display: none;
  }
}
@media (max-width: 700px) {
  .produits .container .left .image {
    width: 275px;
    height: 350px;
  }
}
.headerPage {
  margin-bottom: 25px;
}
.headerPage #bonnesAffaires {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/accueil/AFFAIRE.jpg");
}

.description {
  margin-bottom: 25px;
}
.description .container {
  width: 65%;
  margin: auto;
}
.description .container p {
  font-size: 1.5em;
  text-align: center;
}

#bonnesAffairesProduits .container .left .image.one {
  background-image: url("../images/produits/bonnes-affaires/trilogie.jpeg");
}
#bonnesAffairesProduits .container .left .image.two {
  background-image: url("../images/produits/bonnes-affaires/rose garcia.png");
}
#bonnesAffairesProduits .container .left .image.three {
  background-image: url("../images/produits/bonnes-affaires/listrac.png");
}
#bonnesAffairesProduits .container .left .image.four {
  background-image: url("../images/produits/bonnes-affaires/vieux meyney.jpeg");
}
#bonnesAffairesProduits .container .left .image.five {
  background-image: url("../images/produits/bonnes-affaires/gd rivallon.jpeg");
}

.vignerons {
  margin-bottom: 50px;
}
.vignerons .container {
  background: #1C1C1B;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: #EEEADE;
}
.vignerons .container .left {
  flex: 0.65;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.vignerons .container .left h2 {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.vignerons .container .left hr {
  width: 150px;
  height: 1.5px;
  background: #EEEADE;
  margin: 20px auto 30px auto;
}
.vignerons .container .left p {
  font-size: 26px;
}
.vignerons .container .right {
  flex: 0.75;
  background-image: url("../images/bonnes-affaires/vignerons.jpg");
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  width: initial;
  height: initial;
  object-fit: contain;
  padding: 20px;
}

.objectifs {
  padding: 10%;
}
.objectifs .container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
.objectifs .container .left {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}
.objectifs .container .left h2 {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.objectifs .container .left hr {
  width: 100px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 20px 0 30px 0;
}
.objectifs .container .left p {
  font-size: 26px;
  line-height: 1.5em;
}
.objectifs .container .right {
  flex: 0.75;
  background-image: url("../images/bonnes-affaires/lien.jpg");
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
  padding: 20px;
  box-shadow: 20px -20px 0px white;
}

@media (max-width: 1024px) {
  .description .container {
    width: 90%;
    margin: auto;
  }

  .vignerons .container {
    flex-direction: column-reverse !important;
    margin-top: 35px;
    height: 45em;
  }
  .vignerons .container .right {
    content: "";
    flex: 1;
  }

  .objectifs {
    padding: 0 5% 7.5% 5%;
  }
  .objectifs .container {
    flex-direction: column-reverse;
    height: 35em;
    margin-top: 35px;
  }
  .objectifs .container .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
  }
  .objectifs .container .right {
    padding: 10px;
    box-shadow: 10px -10px 0px white;
  }
}
@media (max-width: 700px) {
  .vignerons .container {
    flex-direction: column-reverse !important;
    margin-top: 35px;
    height: 65em;
  }
  .vignerons .container .right {
    content: "";
    flex: 1;
  }
  .vignerons .container .left {
    padding: 30px 15px;
  }

  .objectifs .container {
    height: 42.5em;
    margin-top: 35px;
  }
  .objectifs .container .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }
  .objectifs .container .left p {
    line-height: 1.2em;
  }
  .objectifs .container .right {
    padding: 0;
    box-shadow: 10px -10px 0px white;
  }
}