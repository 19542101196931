@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.headerPage .container {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EEEADE;
  font-size: 4em;
  text-align: center;
}
.headerPage .container h2 {
  font-family: "Dancing Script SemiBold", "CaviarDreams", sans-serif;
}

@media (max-width: 1024px) {
  .headerPage .container {
    font-size: 3em;
  }
  .headerPage .container h2 {
    line-height: 0.95em;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.twoColumns {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 75%;
  margin: 50px auto;
}
.twoColumns .imageRight {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  text-align: left;
  margin-top: 50px;
}
.twoColumns .imageRight .left {
  flex: 1;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
}
.twoColumns .imageRight .left .titleSection {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
  text-align: left;
}
.twoColumns .imageRight .left .barSection {
  width: 50px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 10px 0 10px 0;
}
.twoColumns .imageRight .left .textSection {
  font-size: 26px;
}
.twoColumns .imageRight .right {
  flex: 0.75;
  text-align: center;
}
.twoColumns .imageRight .right img {
  object-fit: cover;
  width: 80%;
}
.twoColumns .imageLeft {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  text-align: left;
  margin-top: 50px;
}
.twoColumns .imageLeft .right {
  flex: 1;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
}
.twoColumns .imageLeft .right .titleSection {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
  text-align: right;
}
.twoColumns .imageLeft .right .barSection {
  width: 50px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 10px 0 10px auto;
}
.twoColumns .imageLeft .right .textSection {
  font-size: 26px;
  text-align: right;
}
.twoColumns .imageLeft .left {
  flex: 0.75;
  text-align: center;
}
.twoColumns .imageLeft .left img {
  object-fit: cover;
  width: 80%;
}

@media (max-width: 1024px) {
  .twoColumns .imageRight .right img {
    object-fit: cover;
    width: 100% !important;
  }
  .twoColumns .imageLeft .left img {
    width: 100% !important;
  }
}
@media (max-width: 700px) {
  .twoColumns {
    width: 95%;
  }
  .twoColumns .imageRight {
    margin-top: 25px;
    flex-direction: column-reverse;
    text-align: center;
  }
  .twoColumns .imageRight .left {
    display: flex;
  }
  .twoColumns .imageRight .left .titleSection {
    text-align: center;
  }
  .twoColumns .imageRight .left .barSection {
    margin: 10px auto 10px auto;
  }
  .twoColumns .imageRight .right {
    flex: 1;
    text-align: center;
  }
  .twoColumns .imageRight .right img {
    width: 75% !important;
  }
  .twoColumns .imageLeft {
    margin-top: 25px;
    flex-direction: column;
    text-align: center;
  }
  .twoColumns .imageLeft .right .titleSection {
    text-align: center;
  }
  .twoColumns .imageLeft .right .barSection {
    margin: 10px auto 10px auto;
  }
  .twoColumns .imageLeft .right .textSection {
    text-align: center;
  }
  .twoColumns .imageLeft .left {
    flex: 1;
  }
  .twoColumns .imageLeft .left img {
    width: 75% !important;
  }
}
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.descriptionSection .container {
  width: 90%;
  margin: auto;
}
.descriptionSection .container .titleDescription {
  font-size: 44px;
  text-align: center;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.descriptionSection .container hr {
  width: 75px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 25px auto;
}
.descriptionSection .container .description {
  text-align: center;
  font-size: 24px;
}

@media (max-width: 700px) {
  .descriptionSection .container {
    width: 95%;
    margin: auto;
  }
  .descriptionSection .container .description {
    text-align: center;
    font-size: 24px;
  }
}
.headerPage #event {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/event/header.jpg");
}

.selectEvent {
  margin-bottom: 50px;
}
.selectEvent .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  color: #EEEADE;
}
.selectEvent .container .element {
  width: 100%;
  height: 55vh;
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #EEEADE;
  flex-direction: column;
}
.selectEvent .container .element:hover {
  transform: scale(1.1);
}
.selectEvent .container .element h2 {
  font-size: 100px;
  font-family: "Dancing Script Bold", "CaviarDreams_Bold", sans-serif;
  margin-bottom: 25px;
}
.selectEvent .container .element hr {
  width: 150px;
  height: 2.5px;
  background: #EEEADE;
}
.selectEvent .container .element .waiting {
  font-size: 40px;
  font-family: "Dancing Script Bold", "CaviarDreams_Bold", sans-serif;
  text-align: center;
  padding-top: 25px;
}

.festiVins {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/event/acdgc-festivins.png");
}

.estiVins {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../images/event/esti.jpg");
}

/**********
    Design des deux contenus
***********/
.dateEstiVins {
  margin-bottom: 50px;
}
.dateEstiVins .container {
  background: #1C1C1B;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: #EEEADE;
}
.dateEstiVins .container .left {
  flex: 0.65;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.dateEstiVins .container .left h2 {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.dateEstiVins .container .left hr {
  width: 150px;
  height: 1.5px;
  background: #EEEADE;
  margin: 20px auto 30px auto;
}
.dateEstiVins .container .left p {
  font-size: 26px;
}
.dateEstiVins .container .right {
  flex: 0.75;
  background-image: url("../images/event/BANNER.jpg");
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  width: initial;
  height: initial;
  object-fit: contain;
  padding: 20px;
}

.dateFestiVins {
  margin-bottom: 50px;
}
.dateFestiVins .container .dates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}
.dateFestiVins .container .dates .element {
  background: #1C1C1B;
  color: #EEEADE;
  text-align: center;
  padding: 50px 30px;
  border-radius: 8px;
  flex: 0 1 15%;
  margin: 10px;
}
.dateFestiVins .container .dates .element .day,
.dateFestiVins .container .dates .element .month {
  font-size: 24px;
}
.dateFestiVins .container .dates .element .number {
  font-size: 34px;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.dateFestiVins .container .dates .element .level {
  font-size: 24px;
  padding-top: 25px;
}

.feedEstiVins,
.feedFestiVins {
  margin-bottom: 50px;
  /* Create four equal columns that sits next to each other */
  /* Responsive layout - makes a two column-layout instead of four columns */
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
}
.feedEstiVins .row,
.feedFestiVins .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: center;
}
.feedEstiVins .column,
.feedFestiVins .column {
  flex: 24%;
  padding: 0 4px;
}
.feedEstiVins .column img,
.feedFestiVins .column img {
  margin-top: 8px;
  vertical-align: middle;
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}
@media (max-width: 800px) {
  .feedEstiVins .column,
.feedFestiVins .column {
    flex: 50%;
  }
}
@media (max-width: 600px) {
  .feedEstiVins .column,
.feedFestiVins .column {
    flex: 100%;
  }
}

.vigneronsRencontre {
  padding: 10%;
}
.vigneronsRencontre .container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.vigneronsRencontre .container .left {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.vigneronsRencontre .container .left h2 {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.vigneronsRencontre .container .left hr {
  width: 100px;
  height: 2.5px;
  background: #1C1C1B;
  margin: 20px 0 30px 0;
}
.vigneronsRencontre .container .left p {
  font-size: 26px;
  line-height: 1.5em;
}
.vigneronsRencontre .container .left .reservePlace {
  margin-top: 25px;
  margin-bottom: 25px;
}
.vigneronsRencontre .container .left .reservePlace p {
  background: #8C358B;
  color: #EEEADE;
  font-family: "CaviarDreams_Bold", sans-serif;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 16px;
}
.vigneronsRencontre .container .right {
  margin-right: 100px;
  flex: 0.75;
  background-image: url("../images/bonnes-affaires/lien.jpg");
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
  padding: 20px;
  box-shadow: 20px -20px 0px white;
}

@media (max-width: 1024px) {
  .selectEvent .container {
    width: 95%;
  }
  .selectEvent .container .element {
    margin: 24px;
  }
  .selectEvent .container .element h2 {
    font-size: 75px;
    font-family: "Dancing Script Bold", "CaviarDreams_Bold", sans-serif;
    margin-bottom: 25px;
  }

  .dateEstiVins .container {
    flex-direction: column-reverse !important;
    margin-top: 35px;
    height: 45em;
  }
  .dateEstiVins .container .right {
    content: "";
    flex: 1;
  }

  .selectEvent .container {
    width: 95%;
    flex-direction: column;
  }
  .selectEvent .container .element {
    margin: 24px;
    height: 30em;
  }
  .selectEvent .container .element h2 {
    font-size: 75px;
    font-family: "Dancing Script Bold", "CaviarDreams_Bold", sans-serif;
    margin-bottom: 25px;
  }

  .dateEstiVins .container .dates .element {
    flex: 0 0 45%;
  }

  .vigneronsRencontre {
    padding: 0 5% 7.5% 5%;
  }
  .vigneronsRencontre .container {
    flex-direction: column-reverse;
    height: 50em;
    margin-top: 35px;
  }
  .vigneronsRencontre .container .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
  }
  .vigneronsRencontre .container .right {
    padding: 10px;
    margin-right: 0;
    box-shadow: 10px -10px 0px white;
  }
}
@media (max-width: 700px) {
  .dateFestiVins .container {
    flex-direction: column-reverse !important;
    margin-top: 35px;
    height: 65em;
  }
  .dateFestiVins .container .right {
    content: "";
    flex: 1;
  }
  .dateFestiVins .container .left {
    padding: 30px 15px;
  }

  .vigneronsRencontre .container {
    height: 42.5em;
    margin-top: 35px;
  }
  .vigneronsRencontre .container .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 0;
  }
  .vigneronsRencontre .container .left p {
    line-height: 1.2em;
  }
  .vigneronsRencontre .container .right {
    padding: 0;
    box-shadow: 10px -10px 0px white;
  }
}