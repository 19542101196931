@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.sectionImageRight {
  margin-bottom: 50px;
}
.sectionImageRight .container {
  background: #1C1C1B;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: #EEEADE;
}
.sectionImageRight .container .left {
  flex: 1;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.sectionImageRight .container .left .titleSection {
  font-size: 2.5em;
  font-family: "CaviarDreams_Bold", sans-serif;
}
.sectionImageRight .container .left hr {
  width: 150px;
  height: 1.5px;
  background: #EEEADE;
  margin: 25px 0 40px 0;
}
.sectionImageRight .container .left .descriptionSection {
  font-size: 26px;
}
.sectionImageRight .container .right {
  flex: 0.75;
  padding: 20px;
}

@media (max-width: 700px) {
  .sectionImageRight .container {
    flex-direction: column;
    margin-top: 35px;
  }
  .sectionImageRight .container .right {
    content: "";
    flex: 1;
  }
}