@import "../variables.scss";

.sectionImageRight{

    margin-bottom: 50px;

    .container{
        background: $black;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        color: $white;

        .left{
            flex: 1;
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            .titleSection{
                font-size: 2.5em;
                font-family: $bold;
            }

            hr{
                width: 150px;
                height: 1.5px;
                background: $white;
                margin: 25px 0 40px 0;
            }

            .descriptionSection{
                font-size: 26px;
            }

        }

        .right{
            flex: 0.75;
            padding: 20px;

            // Il faut rajouter la photo dans le fichier SCSS qui gère la page en question
        }
    }
}

@media (max-width: $smartphone) {

    .sectionImageRight{
        .container{
            flex-direction: column;
            margin-top: 35px;
          //  height: 40em; A modifier en fonction des pages et des contenus

            .right{
                content: '';
                flex: 1;
            }
        }
    }

}