@import "../variables.scss";

.boxSection{
    margin-bottom: 50px;
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        margin: auto;
        flex-direction: row;
        color: $white;

        // Grosses sections
        .leftBoxSection,
        .rightBoxSection{
            height: 600px;
            gap: 25px;
            flex: 1;
        }

        //Tous les éléments dans les sections
        .topBoxSection,
        .bottomBoxSection,
        .imageBoxSection{
            padding: 25px;

            .titleBoxSection{
                font-size: 35px;
                font-family: $bold;
            }

            .separationBoxSection{
                width: 130px;
                height: 2.5px;
                background: $white;
                margin: 15px 0;
            }

            .textBoxSection{
                font-size: 24px;
                line-height: 1.25em;
            }
        }

        .leftBoxSection{
            display: flex;

            .imageBoxSection{
                // Rajouter une image dans le fichier en question
                margin-right: 25px;
            }
        }


        .rightBoxSection{
            display: flex;
            flex-direction: column;
            flex: 1;

            .topBoxSection{
                flex-grow: 0.5;
                // Rajouter une image dans le fichier en question
            }

            .bottomBoxSection {
                flex-grow: 0.5;
                // Rajouter une image dans le fichier en question
            }
        }
    }
}


@media (max-width: 1300px) {
    .boxSection{
        .container{
            width: 95%;

            // Grosses sections
            .leftBoxSection,
            .rightBoxSection{
                gap: 15px;
            }

            //Tous les éléments dans les sections
            .topBoxSection,
            .bottomBoxSection,
            .imageBoxSection{
                padding: 15px;

                .titleBoxSection{
                    font-size: 30px;
                    font-family: $bold;
                }

                .separationBoxSection{
                    width: 130px;
                    height: 2.5px;
                    background: $white;
                    margin: 15px 0;
                }

                .textBoxSection{
                    font-size: 20px;
                    line-height: 1.25em;
                }
            }

            .leftBoxSection{
                .imageBoxSection{
                    margin-right: 10px;
                }
            }
        }
    }
}


@media (max-width: $smartphone) {
    .boxSection{
        .container {
            width: 95%;
            flex-direction: column;

            // Grosses sections
            .leftBoxSection,
            .rightBoxSection {
                gap: 15px;
            }

            //Tous les éléments dans les sections
            .topBoxSection,
            .bottomBoxSection,
            .imageBoxSection {
                padding: 25px;
            }

            .leftBoxSection{
                .imageBoxSection{
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }

        }
    }
}
