@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
input[type=checkbox][name^=menu] {
  display: none;
}

.dropdown {
  display: none;
}

.header {
  background: #1C1C1B;
  width: 300px;
  position: fixed;
  height: 100%;
  left: 0;
  z-index: 999;
  border-right: #EEEADE 2px solid;
  font-family: "CaviarDreams", sans-serif;
  flex-direction: column;
  pointer-events: auto;
  display: flex;
  max-height: 100%;
  overflow: auto;
}
.header::-webkit-scrollbar {
  display: none;
}
.header .logo-nav {
  font-family: "CaviarDreams", sans-serif !important;
  padding: 20px 0;
  text-align: center;
  border-bottom: #1C1C1B 1px solid;
  color: #EEEADE;
  margin-bottom: 10px;
}
.header .logo-nav #logoHeader {
  width: 300px;
  max-width: 100%;
}
.header .section-element {
  color: #EEEADE;
  font-size: 20px;
}
.header .section-element .navContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.header .section-element .navContainer .linkElement {
  text-decoration: none;
  color: #EEEADE;
  width: 100%;
  border-bottom: 0.5px solid rgba(238, 234, 222, 0.062745098);
  padding-left: 25px;
  background-color: #1C1C1B;
}
.header .section-element .navContainer .linkElement .navElement {
  list-style-type: none;
  width: 100%;
  /******************
      Menu déroulant
  *******************/
}
.header .section-element .navContainer .linkElement .navElement i {
  margin-right: 10px;
  transition: all 0.5s ease;
}
.header .section-element .navContainer .linkElement .navElement a {
  text-decoration: none;
  color: #EEEADE;
}
.header .section-element .navContainer .linkElement .navElement:hover .scrollingMenu {
  overflow: initial;
}
.header .section-element .navContainer .linkElement .navElement:hover .scrollingMenu.one {
  height: 30px;
}
.header .section-element .navContainer .linkElement .navElement:hover .scrollingMenu.two {
  height: 60px;
}
.header .section-element .navContainer .linkElement .navElement:hover .scrollingMenu.three {
  height: 90px;
}
.header .section-element .navContainer .linkElement .navElement:hover .scrollingMenu.four {
  height: 120px;
}
.header .section-element .navContainer .linkElement .navElement:hover .scrollingContainer .down {
  transform: translateX(-10px) rotate(180deg);
}
.header .section-element .navContainer .linkElement .navElement .scrollingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.header .section-element .navContainer .linkElement .navElement .scrollingContainer .icon {
  flex: 0.25;
}
.header .section-element .navContainer .linkElement .navElement .scrollingContainer .scrollingElement {
  flex: 2;
}
.header .section-element .navContainer .linkElement .navElement .scrollingContainer .fa-chevron-down {
  flex: 0.25;
}
.header .section-element .navContainer .linkElement .navElement .scrollingMenu {
  height: 0;
  overflow: hidden;
  transition: 1s all;
}
.header .section-element .navContainer .linkElement .navElement .scrollingMenu .scrollingElement {
  font-size: 16px;
  padding-left: 40px;
  list-style-type: none;
  height: 20px;
}
.header .section-element .navContainer .linkElement .navElement .scrollingMenu .scrollingElement::before {
  content: "-";
  width: 5px;
  height: 1px;
  color: #EEEADE;
  margin-right: 10px;
}
.header .section-element ul li,
.header .section-title {
  margin: 15px 0;
}
.header .copyright {
  display: block;
  flex: 0 0 auto;
  margin-top: auto;
  text-align: center;
  font-size: 16px;
}
.header .copyright .navContainer .linkElement {
  padding-left: 0px;
}
.header .copyright .copyrightText,
.header .copyright .creator {
  opacity: 0.5;
  text-align: center;
}
.header .copyright .navElement:nth-child(2) {
  border-top: 0.5px solid rgba(238, 234, 222, 0.062745098);
  padding-top: 15px;
}
.header .copyright .navElement:nth-child(2) .creator {
  font-size: 14px;
}
.header .copyright .navElement:nth-child(2) .creator a {
  color: #EEEADE;
  font-weight: 800;
}

@media (max-width: 1024px) {
  .header {
    left: -302px;
  }

  .dropdown {
    display: initial;
  }

  .hamburger {
    position: fixed;
    align-self: flex-end;
    top: 10px;
    left: 10px;
    background: #1C1C1B;
    padding: 2.5px 12.5px;
    border-radius: 8px;
    z-index: 10000000000000000;
  }

  label {
    cursor: pointer;
  }

  input[type=checkbox] + label .icon-bar {
    display: block;
    width: 40px;
    height: 5px;
    background-color: #EEEADE;
    margin: 8px 0;
    transition: all 0.5s;
    border-radius: 2px;
  }

  input[type=checkbox] + label .middle-bar {
    left: 0;
    opacity: 1;
  }

  input[type=checkbox]:checked + label .top-bar {
    transform: rotateX(45deg) rotateY(-45deg) translateY(19px);
  }

  input[type=checkbox]:checked + label .middle-bar {
    left: 20px;
    opacity: 0;
    transform: translateX(20px);
  }

  input[type=checkbox]:checked + label .bottom-bar {
    transform: rotateX(45deg) rotateY(45deg) translateY(-19px);
  }

  input[type=checkbox][name^=menu] {
    display: none;
  }

  input[type=checkbox]:checked + label .hamburger {
    left: 310px;
  }

  input[type=checkbox][name^=menu]:checked ~ .header {
    width: 300px;
    left: 0;
  }
}