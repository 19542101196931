@import "../variables.scss";

.twoColumns{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 75%;
    margin: 50px auto;

    .imageRight{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        text-align: left;
        margin-top: 50px;

        .left{
            flex: 1;
            padding: 20px 20px;
            display: flex;
            flex-direction: column;

            .titleSection{
                font-size: 2.5em;
                font-family: $bold;
                text-align: left;
            }

            .barSection{
                width: 50px;
                height: 2.5px;
                background: $black;
                margin: 10px 0 10px 0;
            }

            .textSection{
                font-size: 26px;
            }
        }

        .right{
            flex: 0.75;
            text-align: center;

            img{
                object-fit: cover;
                width: 80%;

            }
        }

    }

    .imageLeft{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        text-align: left;
        margin-top: 50px;

        .right{
            flex: 1;
            padding: 20px 20px;
            display: flex;
            flex-direction: column;

            .titleSection{
                font-size: 2.5em;
                font-family: $bold;
                text-align: right;
            }

            .barSection{
                width: 50px;
                height: 2.5px;
                background: $black;
                margin: 10px 0 10px auto;
            }

            .textSection{
                font-size: 26px;
                text-align: right;
            }
        }

        .left{
            flex: 0.75;
            text-align: center;

            img{
                object-fit: cover;
                width: 80%;

            }
        }

    }
}

@media (max-width: $laptop){
    .twoColumns{
        .imageRight{
            .right{
                img{
                    object-fit: cover;
                    width: 100% !important;

                }
            }
        }

        .imageLeft{
            .left{
                img{
                    width: 100% !important;
                }
            }

        }


    }
}

@media (max-width: $smartphone){
    .twoColumns{
        width: 95%;

        .imageRight{
            margin-top: 25px;
            flex-direction: column-reverse;
            text-align:center;

            .left{
                display: flex;

                .titleSection{
                    text-align: center;
                }

                .barSection{
                    margin: 10px auto 10px auto;
                }

            }

            .right{
                flex: 1;
                text-align: center;
                img{
                    width: 75% !important;

                }
            }

        }

        .imageLeft{
            margin-top: 25px;
            flex-direction: column;
            text-align: center;

            .right{
                .titleSection{
                    text-align: center;
                }

                .barSection{
                    margin: 10px auto 10px auto;
                }

                .textSection{
                    text-align: center;
                }
            }

            .left{
                flex: 1;
                img{
                    width: 75% !important;

                }
            }

        }
    }
}