@import "../variables.scss";

.descriptionSection{
    .container {
        width: 90%;
        margin: auto;

        .titleDescription {
            font-size: 44px;
            text-align: center;
            font-family: $bold;
        }

        hr {
            width: 75px;
            height: 2.5px;
            background: $black;
            margin: 25px auto
        }

        .description {
            text-align: center;
            font-size: 24px;
        }
    }
}


@media (max-width: $smartphone){

    .descriptionSection{
        .container{
            width: 95%;
            margin: auto;

            .description{
                text-align: center;
                font-size: 24px;
            }
        }
    }



}