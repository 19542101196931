@charset "UTF-8";
/**
    Caviar Dreams
**/
@font-face {
  font-family: "CaviarDreams";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}
@font-face {
  font-family: "CaviarDreams_Italic";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}
@font-face {
  font-family: "CaviarDreams_Bold";
  src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}
/**
    Dancing Script
**/
@font-face {
  font-family: "DancingScript";
  src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}
@font-face {
  font-family: "Dancing Script SemiBold";
  src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}
@font-face {
  font-family: "Dancing Script Bold";
  src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}
/** Couleurs **/
/** Polices d'écritures **/
/** Taille d'écran **/
/**
    Changement des éléments particulier
*/
* {
  margin: 0;
  padding: 0;
  font-family: "CaviarDreams", sans-serif;
  transition: all 0.5s ease-in-out;
  scroll-behavior: smooth;
}

body {
  margin-left: 302px !important;
  background-color: #EEEADE;
}

/**
    Design des éléments récurrents modifiés
**/
li {
  list-style-type: none;
}

/**
    Scrollbar design
**/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEEADE;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1C1C1B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1C1C1B;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition {
  transition: all 0.5s ease-in-out;
}

.shadow {
  box-shadow: 4px 4px 8px rgba(28, 28, 27, 0.3725490196);
}

.zoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.hidden {
  display: none !important;
}

.fullBackgroundImage {
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  object-fit: contain;
}

.pdf_download {
  background-color: #8C358B;
  padding: 5px 10px;
  color: #EEEADE;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  border: none;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }
}
.accueil {
  height: 100vh;
  position: relative;
  background-image: linear-gradient(rgba(28, 28, 28, 0.25), rgb(28, 28, 28)), url("../images/accueil/accueil.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #EEEADE;
}
.accueil video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.accueil .home-content {
  position: relative;
  color: #EEEADE;
  text-align: center;
  padding: 200px 20px 20px 20px;
}
.accueil .home-content h1 {
  font-size: clamp(30px, 4em, 40px);
  line-height: 1.1;
}
.accueil .home-content hr {
  width: 75px;
  margin: 25px auto;
  height: 1px;
  background-color: #EEEADE;
}
.accueil .home-content h2 {
  font-size: clamp(10px, 4em, 20px);
  font-weight: 400;
}
.accueil .home-content a {
  display: inline-block;
  font-size: clamp(14px, 1.5vw, 18px);
  border: 1px solid #EEEADE;
  border-radius: 5px;
  background: transparent;
  color: #EEEADE;
  margin: 50px auto 0;
  padding: 12px 20px;
  cursor: pointer;
  text-decoration: none;
}
.accueil .home-content a:hover {
  background-color: #EEEADE;
  color: #1C1C1B;
}

.entreprise .container {
  text-align: center;
}
.entreprise .container h2 {
  font-size: 30px;
  font-family: "CaviarDreams_Bold", sans-serif;
  margin: 50px 0 0 0;
}
.entreprise .container .caroussel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
  margin: 50px auto;
}
.entreprise .container .caroussel img {
  height: 150px;
  width: 200px;
  object-fit: contain;
  flex: 3;
}

.histoire {
  margin: 75px auto 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
}
.histoire .container {
  margin-right: 20%;
}
.histoire .boxOn {
  z-index: 9;
  background-color: rgba(28, 28, 28, 0.7);
  color: #EEEADE;
  width: 691px;
  height: 391px;
  padding: 20px 20px 30px 20px;
  margin: -300px 0 100px 40%;
  border-radius: 4px;
  border: #1C1C1B solid 2px;
}
.histoire .boxOn h2 {
  font-size: 44px;
  margin-bottom: 10px;
}
.histoire .boxOn hr {
  background-color: #EEEADE;
  height: 1.5px;
  width: 15%;
}
.histoire .boxOn p {
  font-size: 1.25em;
  line-height: 1.1em;
  margin: 20px 0 30px 0;
}
.histoire .boxOn a {
  background: #8C358B;
  font-size: 1.8em;
  color: #EEEADE;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  text-decoration: none;
  display: inline-block;
}
.histoire .boxOn a:hover {
  background: #461a45;
}
.histoire .boxBehind {
  border: #1C1C1B solid 2px;
  border-radius: 4px;
  background-image: url("../images/accueil/Magasin.jpg");
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-position: left;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  width: 691px;
  height: 391px;
  object-fit: contain;
  padding: 20px;
}

.slogan .container .right {
  background-image: url("../images/accueil/slogan.jpg");
}

.equipe {
  margin: 50px auto;
}
.equipe .container .presentationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.equipe .container .presentationContainer .element {
  flex: 0 0 25%;
  margin: 30px;
  background: #EEEADE;
}
.equipe .container .presentationContainer .element p {
  color: #1C1C1B;
  font-family: "CaviarDreams_Bold", sans-serif;
  font-size: 1.5em;
}
.equipe .container .presentationContainer .element p:last-child {
  font-size: 1em;
  font-family: "CaviarDreams_Italic", sans-serif;
  color: #1C1C1B;
}
.equipe .container .presentationContainer .element .teams {
  box-shadow: -10px -10px 0px #1C1C1B;
  border-radius: 8px;
  width: 100%;
  height: 20em;
  background-image: url("../images/accueil/team.jpg");
  margin-bottom: 10px;
  color: black;
}
.equipe .container .presentationContainer .element .team1 {
  background-image: url("../images/accueil/photo valéry.jpg");
}
.equipe .container .presentationContainer .element .team2 {
  background-image: url("../images/accueil/photo Franck.jpg");
}
.equipe .container .presentationContainer .element .team3 {
  background-image: url("../images/accueil/photo emilie.jpg");
}
.equipe .container .presentationContainer .element .team4 {
  background-image: url("../images/accueil/photo david.jpg");
}
.equipe .container .presentationContainer .element .team5 {
  background-image: url("../images/accueil/photo léo.jpg");
}
.equipe .container .presentationContainer .element .team6 {
  background-image: url("../images/accueil/photo hugo.jpg");
}
.equipe .container .presentationContainer .element .team7 {
  background-image: url("../images/accueil/team.jpg");
}
.equipe .container .presentationContainer .element .team8 {
  background-image: url("../images/accueil/team.jpg");
}
.equipe .container .presentationContainer .element .team9 {
  background-image: url("../images/accueil/team.jpg");
}

.valeur {
  background: #1C1C1B;
  padding: 50px 25px;
}
.valeur .container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.valeur .container .containerValeur {
  flex: 3;
  color: #EEEADE;
  display: flex;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.valeur .container .containerValeur .valeur {
  flex: 1;
  color: #EEEADE;
  display: flex;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.valeur .container .containerValeur .valeur p,
.valeur .container .containerValeur .valeur i {
  font-size: 3em;
  text-align: center;
  margin: auto;
}
.valeur .container .title {
  margin-left: 25px;
  color: #EEEADE;
}
.valeur .container .title h2 {
  font-size: 3em;
  text-align: left !important;
}
.valeur .container .title hr {
  width: 75px;
  margin-top: 10px;
}

.activite {
  margin: 50px 0;
}
.activite .container .presentationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  color: #EEEADE;
}
.activite .container .presentationContainer .element {
  flex: 0 0 40%;
  margin: 10px 10px;
  background: #EEEADE;
  height: 200px;
  border-radius: 8px;
  background-size: cover;
  /* Resize the background image to cover the entire container */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-position: center center;
  width: 100%;
  object-fit: contain;
  display: flex;
  text-align: left;
  align-items: end;
  flex-wrap: wrap;
  align-content: flex-end;
}
.activite .container .presentationContainer .element div {
  padding: 20px;
}
.activite .container .presentationContainer .element div h2 {
  font-size: 2em;
  font-weight: 600;
}
.activite .container .presentationContainer .element div .description {
  font-size: 1em;
}
.activite .container .presentationContainer .element div a {
  background: #8C358B;
  text-decoration: none;
  padding: 5px 10px;
  color: #EEEADE;
  display: inline-block;
  margin-top: 15px;
}
.activite .container .presentationContainer .element div a:hover {
  background: #461a45;
}
.activite .container .presentationContainer .element.element1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/accueil/AFFAIRE.jpg");
}
.activite .container .presentationContainer .element.element2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/accueil/reception.jpg");
}
.activite .container .presentationContainer .element.element3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/oenologie/oenoBis.jpg");
}
.activite .container .presentationContainer .element.element4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/accueil/grossiste.jpg");
}
.activite .container .presentationContainer .element.element5 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/accueil/entreprise.jpg");
}
.activite .container .presentationContainer .element.element6 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/accueil/event.jpg");
}
.activite .container .presentationContainer .element.element7 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("../images/accueil/otherActivities.jpg");
}

@media (max-width: 1400px) {
  .histoire .container {
    margin-right: 27.5%;
  }

  .equipe .container .presentationContainer .element {
    flex: 0 0 35%;
  }
}
@media (max-width: 1300px) {
  .histoire {
    margin: 75px auto 0 auto;
  }
  .histoire .container {
    margin-right: initial;
  }
  .histoire .boxOn {
    width: 491px;
    height: auto;
    margin: -500px 0 100px 5%;
  }
  .histoire .boxBehind {
    width: 491px;
    height: 491px;
  }
}
@media (max-width: 1024px) {
  body {
    margin-left: -2px !important;
  }

  /********
      Histoire
  *********/
  .histoire .boxOn {
    width: 491px;
    height: auto;
    margin: -500px 0 100px 5%;
  }
  .histoire .boxBehind {
    width: 491px;
    height: 491px;
  }
}
@media (max-width: 700px) {
  body {
    margin-left: -2px !important;
  }

  /********
      Histoire
  *********/
  .histoire .container {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
  }
  .histoire .container .boxOn {
    width: initial !important;
    padding: 20px 20px 30px 20px;
    border-radius: 4px;
    border: #1C1C1B solid 2px;
    margin: 0 0 25px 0 !important;
    text-align: center;
  }
  .histoire .container .boxOn a {
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  .histoire .container .boxOn hr {
    margin: auto;
  }
  .histoire .container .boxBehind {
    width: initial;
    height: 291px;
    padding: initial;
  }

  .slogan .container {
    height: 40em;
  }

  .equipe .container .presentationContainer .element {
    flex: 0 0 95%;
  }

  .valeur {
    padding: 25px 10px;
  }
  .valeur .container {
    flex-direction: column;
  }
  .valeur .container .title {
    margin-left: 0;
  }
  .valeur .container .title hr {
    margin: auto;
    margin-top: 25px;
  }

  .activite .container .presentationContainer .element {
    flex: 0 0 100%;
  }
}