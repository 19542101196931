/**
    Caviar Dreams
**/
@font-face {
    font-family: "CaviarDreams";
    src: url("/assets/fonts/caviar_dreams/CaviarDreams.ttf");
}

@font-face {
    font-family: "CaviarDreams_Italic";
    src: url("/assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf");
}

@font-face {
    font-family: "CaviarDreams_Bold";
    src: url("/assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf");
}

/**
    Dancing Script
**/

@font-face {
    font-family: "DancingScript";
    src: url("/assets/fonts/dancing_script/DancingScript-Regular.ttf");
}

@font-face {
    font-family: "Dancing Script SemiBold";
    src: url("/assets/fonts/dancing_script/DancingScript-SemiBold.ttf");
}

@font-face {
    font-family: "Dancing Script Bold";
    src: url("/assets/fonts/dancing_script/DancingScript-Bold.ttf");
}

/** Couleurs **/
$black: #1C1C1B;
$white: #EEEADE;
$purple: #8C358B;
$purpleDark: #461a45;
$grey: #8E8E8D;

/** Polices d'écritures **/
$normal: "CaviarDreams", sans-serif;
$italic: "CaviarDreams_Italic", sans-serif;
$bold: "CaviarDreams_Bold", sans-serif;

$dancingNormal: "DancingScript", "CaviarDreams", sans-serif;
$dancingMedium: "Dancing Script SemiBold", "CaviarDreams", sans-serif;
$dancingBold: "Dancing Script Bold", "CaviarDreams_Bold", sans-serif;

/** Taille d'écran **/

$computer: 1920px;
$laptop: 1024px;
$smartphone: 700px;

/**
    Changement des éléments particulier
*/

*{
    margin: 0;
    padding: 0;
    font-family: $normal;
    transition: all 0.5s ease-in-out;
    scroll-behavior: smooth;
}

body{
    margin-left: 302px !important;
    background-color: $white;
}

/**
    Design des éléments récurrents modifiés
**/
li{list-style-type: none;}

/**
    Scrollbar design
**/

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $black;
}

/**
    Différents éléments qui sont toujours pareil
**/
.transition{
    transition: all 0.5s ease-in-out;
}

.shadow{
    box-shadow: 4px 4px 8px #1C1C1B5f;
}

.zoom{
    transform: scale(1);
    transition: all 0.5s ease-in-out;

    &:hover{
        transition: all 0.5s ease-in-out;
        transform: scale(1.05);
    }
}

.hidden{
    display: none !important;
}

.fullBackgroundImage{
    background-size: cover; /* Resize the background image to cover the entire container */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position:center center;
    object-fit: contain;
}

.pdf_download{
    background-color: #8C358B;
    padding: 5px 10px;
    color: #EEEADE;
    font-size: 24px;
    border-radius: 5px;
    margin-top: 10px;
    text-decoration: none;
    border: none;
    margin-top: 20px;
}

@media (max-width: $laptop){

    body{
        margin-left: -2px !important;
    }
}





