@import "../variables.scss";

.produits{
    margin-bottom: 75px;
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 50px 100px 0 50px;

        .left{
            flex: 1;
            text-align: right;
            margin: auto;
            display: flex;
            justify-content: right;

            .image{
                width: 500px;
                height: 700px ;
            }
        }

        .center{
            flex: 0.3;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            label{
                margin: 15px 0;
            }

            input[type='radio']:after:hover{
                background: $purple;
            }

            input[type='radio']:after {
                width: 25px;
                height: 25px;
                border-radius: 15px;
                top: -5px;
                left: -5px;
                position: relative;
                background-color: $grey;
                content: '';
                display: inline-block;
                visibility: visible;
                border: 2px solid $white;
                cursor: pointer;
            }

            input[type='radio']:checked:after {
                width: 25px;
                height: 25px;
                border-radius: 15px;
                top: -5px;
                left: -5px;
                position: relative;
                background-color: $purple;
                content: '';
                display: inline-block;
                visibility: visible;
                border: 2px solid $white;
            }
        }

        .right{
            flex: 1;
            width: 50%;

            div{
                h2{
                    font-size: 2.5em;
                    font-family: $bold;
                }

                hr{
                    width: 150px ;
                    height: 2px;
                    background: $black;
                    margin: 7.5px 0 12.5px 0;
                }

                p{
                    font-size: 1.25em;
                    line-height: 1.25em;
                }

                .promotionProduct{
                    color: $white;
                    display: inline-block;
                    border-radius: 8px;
                    font-size: 1.25em;
                    font-weight: bold;
                    margin: 15px 0;
                    padding: 10px 20px;
                    background-color: #8C358B !important;
                    
                }

               
            }
        }
    }

    .offreChangement{
        text-align: right;
        width: 90%;
        margin: auto;
    }
}


@media (max-width: 1300px){
    .produits{
        .container{
            flex-direction: column;
            padding: 5% 5%;
            .left{
                .image{
                    width: 400px;
                    height: 600px;
                }
            }

            .center{
                flex-direction: row;
                label{
                    margin: 30px 15px;
                }
            }
            .right{
                flex: 1;
                width: 95%;
            }
        }

        .offreChangement{
            width: 85%;

            .sautLigne{
                display: none;
            }
        }
    }
}

@media (max-width: $smartphone){
    .produits{
        .container{
            .left{
                .image{
                    width: 275px;
                    height: 350px;
                }
            }
        }
    }
}